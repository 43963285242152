.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}
.layout {
  &-header {
    background: linear-gradient(90deg, #16bab4 0%, #62ccbd 101.46%);
    border-bottom: unset !important;
  }
  &-sidebar {
    background: linear-gradient(
      180deg,
      #11b9b5 0%,
      rgba(13, 180, 150, 0.72) 57.81%,
      rgba(15, 183, 167, 0.54) 100%
    );
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
  }
}
.sidebar-bottom {
  padding-bottom: 50px;
  &-logout {
    cursor: pointer;
    gap: 10px;
    padding-left: 15px !important;
    margin-left: 14px;
    width: 90% !important;
    &:hover {
      background: #13427c !important;
    }
  }
  &-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
  }
  &-upload {
    width: 210px;
    gap: 10px;
    background: #ffffff;
    border-radius: 10px;
    padding: 13px 14px;
    .drop-file {
      width: 182px;
      height: 102px;
      border: 0.8px solid #0c70ed;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #b4b4b4;
      border-radius: 8px;
      gap: 10px;
      img {
        width: 30px;
        height: 30px;
      }
      &-or {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #b4b4b4;
      }
    }
    .button-select {
      background: #0c70ed;
      border-radius: 3px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }
  }
}

.sidebar-brand {
  background: unset;
}

.sidebar {
  // --cui-sidebar-nav-link-active-bg: #DDECFF;
  // --cui-sidebar-nav-group-toggle-show-color: #000;
  background-color: #fff;
}
.sidebar-nav {
  background-color: #fff;
}

.sidebar-nav::-webkit-scrollbar {
  display: none;
}

/* ẩn scrollbar trong Firefox */
.sidebar-nav::-moz-scrollbar {
  display: none;
}

.nav-item {
  display: flex;
  justify-content: center;
  // background-color: #f8f9fa;
  .nav-link {
    width: 90%;
    flex: unset;
    font-weight: 600;
    font-size: 16px;
    color: #000;
    // background: #f8f9fa;
    gap: 10px;
    height: 50px;
    border-radius: 10px;
    &:hover {
      background: #8e7cc3 !important;
      border-radius: 10px;
    }
  }
  .active {
    border-radius: 10px;
    background-color: #674ea7 !important;
    &::before {
      content: '';
      width: 6px;
      height: 50px;
      // background: #F8F9FA;

      display: table;
      position: absolute;
      border-radius: 3px;
      left: 0;
    }
  }
}

.nav-group {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 12px;
  background: #f8f9fa !important;
  &:hover {
    border-radius: 10px !important;
  }
  .nav-link {
    width: 100%;
    flex: unset;
    font-weight: 400;
    font-size: 11px;
    gap: 10px;
    // background: #F8F9FA !important;
    color: #000 !important;
    border-radius: 10px !important;
  }
  .show {
    .nav-link {
      padding-left: 30px !important;
    }
  }
  .nav-group-items {
    .item-sidebar {
      height: 50px;
      flex: unset;
      font-weight: 400;
      font-size: 11px;
      gap: 10px;
      .nav-link {
        padding-left: 30px !important;
      }
    }

    .active {
      background-color: #ddecff !important;
      &::before {
        content: '';
        width: 6px;
        height: 50px;
        background: #f8f9fa !important;
        display: table;
        position: absolute;
        border-radius: 3px;
        left: -10px;
      }
    }
  }
}

.item-sidebar-div {
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
}

.show {
  .nav-group-toggle {
    color: #fff !important;
    background: #0c70ed !important;
    height: 50px;
    border-radius: 10px !important;
  }
}

.nav-group-toggle {
  // color: #F8F9FA !important;
  height: 50px;
  background: unset !important;
  &:hover {
    border-radius: 10px !important;
    background-color: #4397ff !important;
  }
  // padding-left: unset !important;
}

.header-breadcrum {
  gap: 10px;
  &-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    color: #000;
    background: #fff;
    @include responsive(md) {
      font-size: 14px;
      line-height: 16px;
    }
    @include responsive(max-sm) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &-subtext {
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    color: #000;
    display: flex;
    background: #fff;
    &::before {
      content: '';
      height: 22px;
      width: 2px;
      display: block;
      margin-right: 10px;
      background: #fff;
    }
    @include responsive(md) {
      font-size: 14px;
      line-height: 16px;
    }
    @include responsive(max-sm) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.breadcrum-pc {
  @include responsive(max-sm) {
    display: none;
  }
  @include responsive(md) {
    display: none;
  }
}

.language-pc {
  @include responsive(max-sm) {
    display: none;
  }
  @include responsive(md) {
    display: none;
  }
}

.logo-sp {
  @include responsive(md) {
    width: 100%;
    .logo {
      display: block !important;
    }
  }
  @include responsive(max-sm) {
    width: 100%;
    .logo {
      display: block !important;
    }
  }
}
.component__header {
  position: sticky;
  top: 0;
  z-index: 1029;
  .breadcrum-sp {
    height: 70px;
    width: 100%;
    background: #fff;
    @include responsive(md) {
      display: flex !important;
    }
    @include responsive(max-sm) {
      display: flex !important;
    }
  }
}
