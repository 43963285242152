.components {
  &__table {
    overflow-x: auto !important;
    position: relative;
    padding-bottom: 16px;
    max-height: 70vh;
    thead {
      th {
        position: sticky;
        top: 0px;
        text-align: start !important;
        background-color: #674ea7;
        border: unset;
        // position: relative;
        padding: 0px;
        padding-left: 0px;
        white-space: pre !important;
        font-weight: 700;
        font-size: 15px;
        line-height: 13.28px;
        text-align: center;
        color: #ffffff;
        height: 40px;
        vertical-align: middle;
        z-index: 1;
        &:first-child {
          padding-left: 10px;
          border-top-left-radius: 8px;
        }
        div {
          line-height: 100%;
          vertical-align: middle;
        }
        span {
          margin-top: -2px;
        }
        &:last-child {
          border-right: unset;
          border-top-right-radius: 8px;
        }
        &.detail-row {
          background-color: #ebedef;
          border: 0px solid #ebedef;
        }
        &.no-item {
          padding-left: 0px;
        }
        .form-check-input:checked,
        .form-check-input[type='checkbox']:indeterminate {
          border-color: white !important;
        }
      }
    }
    tbody {
      tr {
        height: 40px;
        td {
          // border-bottom: 0.1px solid #b4b4b4;
          line-height: 1;
          padding: 0px;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 400;
          font-size: 14px;
          line-height: 13.28px;
          color: #000000;
          background: white;
          vertical-align: middle;
          &:first-child {
            padding-left: 10px;
          }
          &:last-child {
            border-right: unset;
          }
          &.detail-row {
            background-color: #ebedef;
            border-bottom: 1px solid #ebedef;
          }
        }
      }
    }
    nav {
      ul {
        justify-content: center !important;
      }
      li {
        .disabled {
          background-color: #cecece;
        }
        .page-link {
          color: #727272;
          padding: 3px 8px 3px 8px;
          font-size: 11px !important;
        }
        .page-link:hover {
          color: #ffffff;
          background-color: #4397ff;
          border-color: #4397ff;
        }
      }
      .active {
        .page-link {
          background-color: #4397ff;
          color: #ffffff;
          border-color: #4397ff;
        }
      }
    }
    .total__block {
      bottom: 50px;
      width: 200px;
      height: 40px;
      color: #000;
      font-family: Tahoma;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .no__pagination {
      bottom: 0px;
    }
    .pagination {
      float: right !important;
    }
  }
}
