.form-input {
  &-label {
    color: #696f79;
    font-weight: 500;
    font-size: 11px;
    line-height: 13.28px;
  }
  &-input {
    border: 1px solid #8692a6 !important;
    border-radius: 6px !important;
    padding-left: 50px !important;
    height: 60px !important;
    &:focus-visible {
      border: 1px solid #11b9b5 !important;
    }
  }
  &-iconBefore {
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    color: #11b9b5;
  }
  &-iconAfter {
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: #11b9b5;
    cursor: pointer;
  }
  @include responsive(md) {
    &-label {
      font-size: 14px !important;
      line-height: 17px !important;
    }
    &-input {
      height: 45px !important;
    }
  }
  @include responsive(max-sm) {
    &-label {
      font-size: 14px !important;
      line-height: 17px !important;
    }
    &-input {
      height: 45px !important;
    }
  }
}

.input {
  border: 1px solid #6a6a6a !important;
  border-radius: 4px !important;
  height: 30px !important;
  margin-bottom: 0px !important;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.28px;
  &:focus {
    box-shadow: none !important;
    border: 1px solid #6a6a6a !important;
    border-radius: 4px !important;
  }

  &:disabled {
    background: #ffffff !important;
    border: none !important;
    color: #000 !important;
    border-radius: 4px !important;
  }
  &:read-only {
    background: #ffffff !important;
    border: 1px solid #eceded !important;
    border-radius: 4px !important;
  }
}

.form-select {
  border: 1px solid #6a6a6a;
  border-radius: 4px !important;
  height: 30px !important;
  margin-bottom: 0px !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.28px;
  &:focus {
    box-shadow: none !important;
    border: 1px solid #6a6a6a !important;
    border-radius: 4px !important;
  }

  &:disabled {
    background: #ffffff !important;
    border: none !important;
    color: #000 !important;
    border-radius: 4px !important;
  }
}

.picker-input-group {
  height: 33px;
  border: 1px solid #6a6a6a;
  border-radius: 4px !important;
  margin-bottom: 0px !important;

  & input {
    border: none !important;
    border-radius: 4px !important;
  }

  & .input-group-text {
    border: none !important;
    border-radius: 4px !important;
  }

  &:focus {
    box-shadow: none !important;
    border: 1px solid #6a6a6a !important;
    border-radius: 4px !important;
  }

  &:disabled {
    background: #ffffff !important;
    border: none !important;
    color: #000 !important;
    border-radius: 4px !important;
  }
}

.form-check-input:checked {
  background-color: #674ea7 !important;
  border-color: #674ea7 !important;
  &:focus {
    background-color: #674ea7 !important;
    border-color: #674ea7 !important;
    box-shadow: none !important;
  }
}

.textarea {
  border: 1px solid #6a6a6a;
  border-radius: 4px !important;
  min-height: 37px !important;
  height: 37px;
  margin-bottom: 0px !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 13.28px;
  &:focus {
    box-shadow: none !important;
    border: 1px solid #6a6a6a !important;
    border-radius: 4px !important;
  }

  &:disabled {
    background: #ffffff !important;
    border: none !important;
    color: #000 !important;
    border-radius: 4px !important;
  }
  &:read-only {
    background: #ffffff !important;
    border: 1px solid #eceded !important;
    border-radius: 4px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.input-pw {
  padding-right: 30px !important;
}

input[type='checkbox'] {
  height: 16px !important;
  width: 16px;
}
