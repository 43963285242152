.components__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  .icon_btn {
    display: flex;
    align-items: center !important;
    margin-right: 10px !important;
  }
}

.btn-primary,
.btn-secondary,
.btn-cancel,
.btn-import {
  color: var(--white-100, #fff);
  text-align: center;
  font-family: Tahoma;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.9px;
  text-align: center;
  height: 30px;
  border: none;
  &:disabled {
    background: #c5c5c5 !important;
    color: black !important;
    border: none !important;
  }
}

.btn-primary {
  color: #ffffff;
  background: #674ea7;
  border-radius: 5px;
  width: 120px;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
}
.form-control input {
  border-radius: 5px;
}

.btn-secondary {
  color: #674ea7;
  background: #ffffff;
  border-radius: 8px;
  width: 120px;
  text-align: center;
  border: 1px solid #674ea7 !important;
  &:hover {
    background: #e6f1ff;
    color: #674ea7;
    border: 1px solid #674ea7;
  }
}

.btn-cancel {
  color: #e0000d;
  background: #ffffff;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #e0000d !important;
  &:hover {
    background: #ffe2e3;
    color: #e0000d;
  }
}

.btn-import {
  color: #ffffff;
  background: #00a04a;
  border-radius: 8px;
  width: 120px;
  text-align: center;
  &:hover {
    background: #00a04a;
    opacity: 0.6;
  }
}
.btn-gray {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.02em;
  height: 25px;
  border: none;
  &:disabled {
    background: #c5c5c5 !important;
    color: #a9a9a9 !important;
    border: none !important;
  }
  color: #000;
  background: #c5c5c5;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  &:hover {
    background: #a1a1a1;
    color: #fff;
  }
}

.btn-edit--table {
  display: flex;
  max-height: 25px !important;
  justify-content: center;
  align-items: center;
  background: #636f83;
  font-weight: 400;
  font-size: 13px;
  width: 70px;
  border-radius: 4px !important;
  &:disabled {
    background: #c5c5c5 !important;
    color: #a9a9a9 !important;
    border: none !important;
  }
  &:hover {
    opacity: 0.6;
    background: #636f83;
  }
  &image {
    max-height: 13px !important;
  }
}

.btn-action--permission {
  display: flex;
  max-height: 17px !important;
  justify-content: center;
  align-items: center;
  background: #ffcc02;
  font-weight: 400;
  font-size: 11px;
  border-radius: 4px !important;
  color: #000;
  
  &:disabled {
    background: #c5c5c5 !important;
    color: #a9a9a9 !important;
    border: none !important;
  }
  &:hover {
    opacity: 0.6;
    background: #ffcc02;
    color: #000;
  }
}
.btn-action--delete {
  display: flex;
  max-height: 17px !important;
  justify-content: center;
  align-items: center;
  background: #ef366e;
  font-weight: 400;
  font-size: 11px;
  border-radius: 4px !important;
  color: #fff;
  &:hover {
    background: #e35a86;
    color: #fff;
  }
  &:disabled {
    background: #ff9cba;
    color: #fff;
  }
  &:active {
    background: #ef366e !important;
    color: #fff;
  }
}

.btn-action--purple {
  color: #000;
  background: #6b71ed;
  display: flex;
  max-height: 17px !important;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  width: 62px;
  border-radius: 2px !important;
  &:hover {
    background: #959aff;
    color: #000;
  }
  &:disabled {
    background: #babdff;
    color: #000;
  }
}
.btn-action--blue {
  color: #fff;
  background: #0c70ed;
  display: flex;
  max-height: 17px !important;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  border-radius: 4px !important;
  &:hover {
    background: #1e6dcf;
    color: #fff;
  }
  &:disabled {
    background: #9cc8ff;
    color: #fff;
  }
}

.btn-action--green {
  color: #fff;
  background: #00aa30;
  display: flex;
  max-height: 17px !important;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  border-radius: 4px !important;
  &:hover {
    background: #2ca14d;
    color: #fff;
  }
  &:disabled {
    background: #66a778;
    color: #fff;
  }
}
.badge_status-true {
  background: #51cc8a;
  display: flex;
  width: 45px;
  padding: 0px;
  border-radius: 8px;
  max-height: 17px;
  justify-content: center;
  font-size: 11px;
  color: #fff;
  &:hover {
    background: #6eeca8;
  }
}

.badge_status-false {
  background: #ef366e;
  display: flex;
  width: 45px;
  padding: 0px;
  border-radius: 8px;
  max-height: 17px;
  justify-content: center;
  font-size: 11px;
  color: #fff;
  &:hover {
    background: #f45082;
  }
}

.status-active {
  background: #51cc8a;
  color: #fff;
  display: flex;
  width: 45px;
  padding: 2px;
  border-radius: 4px;
  justify-content: center;
}

.status-inactive {
  background: #ef366e;
  display: flex;
  width: 45px;
  padding: 2px;
  border-radius: 4px;
  justify-content: center;
}

.btn-delete {
  color: #ffffff;
  background: #ce0000;
  border-radius: 8px;
  width: 120px;
  font-size: 14px;
  text-align: center;
  &:hover {
    background: #ce0000;
  }
  &:active {
    background: #ce0000 !important;
  }
}
