video._2WNzJ {
  -o-object-position: center;
     object-position: center;
  border-radius: 4px;
  min-height: 0;
  min-width: 0;
}

._34YL4 {
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0);
}

._1y2fW {
  border: 2px solid #28994e;
}

._30KSQ {
  background: #c4c4c4;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

._2LCHl {
  position: absolute !important;
  left: 3px !important;
  bottom: 3px !important;
  min-width: 160px !important;
  max-width: 172px !important;
  height: 20px !important;
  border-radius: 6px !important;
  opacity: 0.4 !important;
  background: #000 !important;
  color: #fff !important;
  padding: 4px !important;
  font-weight: 500 !important;
  font-size: 11.5px !important;
  display: flex !important;
  gap: 8px !important;
  justify-content: flex-start !important;
}

._2LCHl ._55u8f {
  white-space: nowrap;
  justify-self: start;
}

._2LCHl ._213aF {
  justify-self: center;
}

._2LCHl ._te685 {
  display: none;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._1FIqv {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

._3LzZj {
  color: #981010;
}

._2kKks {
  display: flex;
  gap: 16px;
  align-items: center;
}

._2kKks button {
  margin: 0 !important;
}

._3w3nM {
  display: inline-block;
  margin: 4px;
  white-space: nowrap;
}

._hRq5k {
  display: inline-block;
  border-radius: 44px;
  border: none;
  background: #2f2f2f;
  cursor: pointer;
  color: white;
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
}

._hRq5k:active {
  border: none;
}

._hRq5k:hover {
  background: #434343;
}

._hRq5k:disabled {
  opacity: 0.5;
  cursor: default;
}

._hRq5k ._1Nxni {
  display: inline-block;
}

button._Rt0RH {
  background: #981010;
}

button._Rt0RH:hover {
  background: #b81212;
}

button._3Lz0y {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 12px;
  padding-right: 15px;
  margin-left: 0;
}

button._3Bgvz {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}

._1VLMM {
  display: inline-block;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-right: 8px;
  margin-left: -12px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 20px;
}

._3XXS4 {
  background: #2f2f2f;
  border-radius: 4px;
  margin-bottom: 5px;
}

ul._dKNN7 {
  border: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-inline: 0;
}

ul._dKNN7 li {
  font-size: 14px;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

ul._dKNN7 li:hover {
  background: #434343;
}

ul._dKNN7 li:first-child {
  border: 0;
}

._14898 {
  width: 100%;
  height: 100%;
  position: relative;
}

._17KXW {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

._3QhLc {
  display: block;
  background-color: #5a8bff;
  cursor: pointer;
  padding: 12px 20px;
  border: none;
  color: white;
  font-weight: 700;
  filter: drop-shadow(3px 7px 13px rgba(0, 0, 0, 0.17));
  border-radius: 8px;
  text-transform: uppercase;
}

._3QhLc:active {
  border: none;
}

._3QhLc ._1Nxni {
  display: inline-block;
  margin-right: 12px;
}

._2sm4e {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto -webkit-min-content;
  grid-template-rows: auto min-content;
  min-height: 0;
}

._2VE66 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100%;
  gap: 8px;
  overflow: hidden;
}

._2kKDi {
  grid-column: 1 / 11;
}

._gA0MQ {
  grid-column: 11 / 13;
  display: grid;
  gap: 8px;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  overflow-y: scroll;
}

._1smc2 {
  height: 100px;
  display: grid;
  align-items: center;
  justify-items: center;
}

._2HbZ0 {
  display: grid;
  gap: 8px;
  overflow: hidden;
  height: 100%;
  align-items: center;
  justify-items: center;
}

._2ltyS {
  grid-template-columns: auto;
  grid-template-rows: auto;
}

._2vumQ {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}

._X-1Rk {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

._3rLR0 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

._3qF9s {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

._mDdNR {
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

._2zM8F {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto -webkit-min-content -webkit-min-content;
  grid-template-rows: auto min-content min-content;
  min-height: 0;
  row-gap: 16px;
}

._9PXrI {
  display: grid;
  overflow: hidden;
}

._Tl5ht {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 8px;
       column-gap: 8px;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

._Tl5ht::-webkit-scrollbar {
  width: 0;
  height: 0;
}

._jGXiG {
  flex-shrink: 0;
  width: 180px;
}

._3MB5b {
  height: 50px;
  display: grid;
  align-items: center;
  justify-items: center;
}
