.tab-language-mobile {
  display: none;
}
.preJoinPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 100vh;
}

.header-preJoin {
  width: 30%;
}

.logoTechzenImgParent {
  width: 40%;
}

.logoTechzen {
  display: flex;
  justify-content: center;
  width: 80%;
}

.logoTechzenImg {
  width: 225px;
}

.preJoinBody {
  background-color: #fff;
  border-radius: 14px;
  width: 80%;
  padding: 20px;
}

.title-ip {
  color: #000000;
  text-align: center;
  font-family: SF UI Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.title-preJoin {
  color: #151515;
  text-align: center;
  font-family: SF UI Display;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.title-sortby {
  color: #000000;
  text-align: center;
  font-family: SF UI Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.body-join-room {
  display: flex;
  gap: 10px;
}

.videocall-and-chat {
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  background: #fff;
  padding: 30px;
  width: 100%;
}

.videocall-and-chat h2,
.only-chat h2 {
  margin-bottom: 10px !important;
  color: #333;
  display: flex;
  margin: auto;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5em;
}

.only-chat {
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  background: #fff;
  padding: 1%;
  width: 20%;
  display: none;
  row-gap: 10px;
  flex-direction: column;
}

.japanese-chat {
  border-radius: 10px;
  background: #f3edff;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

.vietnamese-chat {
  border-radius: 10px;
  background: #e6f0ff;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

.japanese-chat-name,
.vietnamese-chat-name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.vietnamese-chat label,
.japanese-chat label {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.body-preJoin {
  padding-top: 10px;
}

.user-preJoin {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.user-block-ja,
.user-block-vn {
  cursor: pointer;
  gap: 5px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #f3edff;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
}

.user-block-ja:hover,
.user-block-vn:hover {
  background: #c4adf1;
}

.user-block-ja:hover .user-name {
  color: #e6f0ff !important;
}

.user-block-ja .user-block_content,
.user-block-vn .user-block_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-left: 20px;
}

.user-block-ja-disabled {
  cursor: not-allowed;
  opacity: 0.6;
  background-color: #bcbcbc;
}

.user-block-ja-disabled label {
  cursor: not-allowed;
}

.user-block-ja-disabled:hover {
  background: #bcbcbc;
}

.user-block-ja-disabled:hover .user-name {
  color: #000 !important;
}

.input-group {
  position: relative;
}

.input {
  border: solid 1.5px #9e9e9e;
  z-index: 999;
  border-radius: 1rem;
  background: none;
  padding: 7px;
  font-size: 14px;
  color: black;
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.user-label {
  position: absolute;
  top: -9px;
  left: 15px;
  color: black;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input:focus,
input:valid {
  outline: none;
  border-radius: 10px;
  border: 2px solid #e9dffd;
  height: 47px;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-2%) scale(0.8);
  background-color: #f3edff;
  padding: 0 0.2em;
  color: #2196f3;
}

.user-block-vn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 10px;
  background: #e6f0ff;
  height: 100px;
}

.user-icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.room-icon {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.stop-stt-icon {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.member-icon {
  width: 15px;
  height: 15px;
}

.layout-member-icon {
  display: flex !important;
  align-items: center;
  display: flex;
  gap: 5px;
  margin-left: auto;
  padding-right: 20px;
  margin-top: 50px;
}

.user-name {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  padding: 5px;
}

.user-total-room {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: -3px;
}

.btn-audio,
.btn-video {
  color: #151515;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background: #fff;
  width: 200px;
  height: 50px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}

.btn-audio:hover,
.btn-video:hover {
  background-color: unset;
}
/* Comment style control bar */
/* button._styles-module__dropdown__3Lz0y {
  display: none;
} */

.btn-start-conversation {
  width: 300px;
  height: 64px;
  font-size: 20px;
  border-radius: 10px;
  background: linear-gradient(180deg, #8962d0 0%, #542a9f 93.12%);
  box-shadow: -2px 2px 8px 0px rgba(255, 255, 255, 0.36) inset;
}

.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  border-radius: 3px;
  overflow: hidden;
}

.inp .label {
  position: absolute;
  top: 10px;
  left: 45px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  transform-origin: 0 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  pointer-events: none;
}

.inp input {
  appearance: none;
  display: flex;
  margin: auto;
  width: 70%;
  padding: 10px 12px 0 12px;
  height: 47px;
  font-size: 16px;
  font-weight: 400;
  background: none;
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  box-shadow: 0 -1px 0 rgba(#333, 0.3);
  color: #333;
  transition: all 0.15s ease;
}

.inp input:hover {
  background: rgba(#000, 0.04);
  box-shadow: inset 0 -1px 0 rgba(#000, 0.5);
}

.inp input:not(:placeholder-shown) + .label {
  color: rgba(#000, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}

.inp input:focus {
  background: none;
  outline: none;
  box-shadow: inset 0 -2px 0 #845bcf;
}

.inp input:focus + .label {
  color: #845bcf;
  transform: translate3d(0, -12px, 0) scale(0.75);
}

/* START POP UP PASSWORD*/
.popup-password {
  display: flex !important;
  margin: auto !important;
  height: 30% !important;
  width: 30% !important;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  padding-top: 0px;
}

.div-password-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 15px;
}

.div-btn-startRoom {
  width: 100%;
  display: flex;
  justify-content: center;
}

.start-room-btn {
  width: 80%;
  height: 50px;
  border-radius: 8px;
  background: #8962d0;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 600;
}

.input-item {
  background: #fff;
  color: #333;
  padding: 14.5px 0px 15px 9px;
  border-radius: 5px 0px 0px 5px;
}

#eye {
  color: #333;
  cursor: pointer;
  margin-left: -30px;
}

.password-input {
  width: 74%;
  padding: 10px 15px;
  font-size: 16px;
  color: #5e6472;
  outline: none;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  transition: 0.2s linear;
  background: #fff;
}

.div-passwors-input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body-form-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.body-form-password h2 {
  margin: unset;
  font-size: 22px;
  color: #333;
}
/* END POP UP PASSWORD*/

/* START TABS */
.tab-language-mobile {
}
.tab-language {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.japanese-chat-tab,
.vietnamese-chat-tab {
  height: 45px;
  border-radius: 10px;
  background: #f3edff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50%;
  flex-direction: column;
}

.vietnamese-chat-tab {
  background: #e6f0ff;
}
.japanese-chat-tab {
  background: #f3edff;
}

.vietnamese-chat-tab label,
.japanese-chat-tab label {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* END TABS */

/* START RESPONSIVE */

@media screen and (max-width: 1100px) {
  .user-preJoin {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 920px) {
  .popup-password {
    width: 60% !important;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 25px;
  }

  .preJoinBody {
    width: 90%;
  }
  .user-preJoin {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .user-block-ja .user-block_content,
  .user-block-vn .user-block_content {
    gap: 0;
  }
  .user-block-ja .user-block_content .user-name,
  .user-block-vn .user-block_content .user-name {
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 540px) {
  .tab-language-mobile {
    display: block;
  }

  .logoTechzenImgParent {
    width: 100%;
  }

  .logoTechzenImg {
    width: 150px;
  }
  .logoTechzen {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .body-preJoin {
    display: block !important;
  }
  .tab-language {
    padding: unset;
  }
  .user-preJoin {
    grid-template-columns: repeat(1, 1fr);
  }
  .popup-password {
    width: 75% !important;
  }
  .prejoinOption {
    display: flex !important;
  }

  .header-preJoin {
    width: 100% !important;
    margin-top: 20px;
  }
  .rooms-header {
    display: block !important;
  }
  .sort-rooms {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    width: 100%;
    gap: 0rem !important;
  }
  .sort-rooms-button {
    transform: scale(0.6);
  }
  .add-device-key-button {
    transform: scale(0.6);
    width: 214px !important;
  }
  .device-info {
    display: flex !important;
    gap: none !important;
  }
  .ip-info {
    font-size: 90%;
    width: 100% !important;
  }
  .user-preJoin {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 414px) {
  .popup-password {
    inset: 30px;
  }
}

/* END RESPONSIVE */
