.components {
  &__pagination {
    &__total {
      color: #000;
      font-family: Tahoma;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__section {
      .page-item > a.page-link {
        color: #727272;
        padding: 7px 10px;
        color: #000;
        font-family: Tahoma;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .active > .page-link {
        background: #0c70ed;
        border-color: #0c70ed;
        border-radius: 0px;
        padding: 7px 10px;
        color: var(--white-100, #fff);
        font-family: Tahoma;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .page-item > a.page-link:hover {
        background: #0c70ed;
        border-color: #0c70ed;
        color: #ffffff !important;
        padding: 7px 10px;
        color: var(--white-100, #fff);
        font-family: Tahoma;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .page-link.disabled,
      .disabled > .page-link {
        background-color: #cecece;
      }
    }

    &__empty_block {
      width: 0.5px;
      height: 1px;
      padding-left: 22px;
      padding-right: 22px;
      background-color: #ebedef;
      &__total {
        width: 170px;
        height: 40px;
        background: #ebedef;
        border: 1px solid #ebedef;
        margin-right: auto;
      }
    }
  }
}
