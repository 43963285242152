body {
  padding: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(../../example/src/assets/images/chat/background.png),
    lightgray 50% / cover no-repeat;
  color: white;
  margin: 0;
  /* overflow: hidden; */
  height: 100vh;
  font-size: 14px;
  font-family: 'SF UI Display', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #5a8bff;
}

a:hover {
  opacity: 0.8;
}

.container-2 {
  box-sizing: border-box;
  background: url(../../example/src/assets/images/chat/background.png),
    lightgray 50% / cover no-repeat;
  flex-direction: row;
}

.prejoin {
  display: grid;
  align-items: center;
  justify-items: center;
}

.prejoin main {
  width: 750px;
}

.prejoin h2 {
  text-align: center;
  margin-top: 30px;
}

.prejoin hr {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.prejoin footer {
  font-size: 14px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.entrySection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;
  gap: 0.8rem;
}

@media only screen and (max-width: 800px) {
  .container-2 {
    padding: 30px;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }

  .entrySection {
    grid-template-columns: 1fr;
    margin-top: 20px;
    gap: 20px;
  }

  .prejoin main {
    width: 100%;
  }
}

.entrySection .label {
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 10px;
}

.entrySection input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 16px;
  background-color: transparent;
  color: white;
  padding: 8px;
}

.entrySection input[type='text'] {
  width: calc(100% - 16px);
}

.entrySection div {
  vertical-align: middle;
}

.videoSection {
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-top: 20px;
}

.videoSection video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.videoSection .placeholder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #2f2f2f;
}

.controlSection {
  margin-top: 30px;
  display: grid;
  grid-template-columns: auto auto minmax(100px, min-content);
}

.controlSection .right {
  grid-column: 3 / 4;
}

/* .roomContainer {
  height: calc(100vh - 100px);
} */

.roomContainer_content_chat {
  height: 100%;
  position: relative;
}

.roomContainer_content_chat h1 {
  margin-top: 0;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topBar .right {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  gap: 16px;
}

.iconButton {
  cursor: pointer;
}

.iconButton:disabled {
  cursor: auto;
}

.participantCount {
  font-size: 14px;
  color: #000;
}

.participantCount span {
  display: inline-block;
  margin-left: 5px;
}

.options {
  display: grid;
  grid-template-columns: repeat(2, fit-content(120px)) auto;
  gap: 8px;
}

.options label {
  text-align: center;
  margin-left: 0.2rem;
  font-size: 0.9rem;
}

.video {
  border-radius: 14px;
  padding: 20px;
  padding-bottom: 30px;
  background: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
}

.video-kpt {
  border-radius: 0px !important;
  padding: 0px !important;
  background: #fff;
  box-shadow: none;
  height: 100% !important;
}

.viewport-col {
  padding: 20px;
  height: 100vh;
}

.viewport-col-video {
  padding: 20px;
  height: 100vh;
}

.viewport-col-kpt {
  /* padding: 20px; */
  height: 100vh;
}

.kpt-container {
  border-radius: 14px;
  height: 100%;
  background-color: white;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
  .viewport-col-video {
    display: none;
  }
  .viewport-col {
    padding: 0;
    height: 100vh;
  }
}

.chat {
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  margin-left: 1%;
  padding: 0;
  height: 100%;
  width: 99%;
}

.inputChat {
  position: absolute;
  bottom: 20px;
  width: 90%;
  font-size: 16px;
  padding: 5px 5px 5px 15px;
  margin: 0 0% 0 3%;
}

.chat-container,
.voice-container {
  height: 80vh;
  overflow: auto;
}

.tab-chat {
  height: 70%;
  position: relative;
}

@media (max-height: 900px) {
  .tab-chat {
    height: 68%;
  }
}

@media (max-height: 800px) {
  .tab-chat {
    height: 62%;
  }
}

@media (max-height: 700px) {
  .tab-chat {
    height: 56%;
  }
}

.tab-chat-content li {
  list-style: none;
  display: flex;
}

.tab-chat-content {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 98.5%;
}

.tab-chat-content_scroll {
  overflow-y: scroll;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column-reverse;
}

.tab-chat-content_scroll-hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tab-chat-content_scroll-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.tab-chat-content_scroll ul {
  padding: 0;
}

.text-chat {
  border-radius: 10px;
  margin: 20px 45px;
  padding: unset;
  background-color: #fff;
  width: fit-content;
  position: relative;
  color: #333;
  max-width: 80%;
  word-break: break-word;
}

.avater-user {
  position: absolute;
  top: 0px;
}

.name_user {
  color: #2f2f2f;
  /* position: absolute;
  top: -20px; */
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

.tab-chat > ul {
  margin: unset;
}

/* CSS styles for the textarea */
.beautiful-textarea {
  height: 100px;
  position: absolute !important;
  width: 99%;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  outline: none;
  resize: none;
  padding: 10px 0 0 10px;
}

.textarea-container {
  height: 170px;
  margin-left: 1%;
  position: absolute !important;
  bottom: 20px;
  width: 97%;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  border: 2px solid #e9dffd !important;
  border-radius: 8px;
  outline: none;
  resize: none;
  transition: border-color 0.3s ease;
  background-color: white;
}

.textarea-container-image {
  height: 210px;
  margin-left: 1%;
  position: absolute !important;
  bottom: 20px;
  width: 97%;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  border: 2px solid #e9dffd !important;
  border-radius: 8px;
  outline: none;
  resize: none;
  transition: border-color 0.3s ease;
  background-color: white;
}

.beautiful-textarea-edit {
  border: 2px solid #723ddb !important;
}

.beautiful-textarea .beautiful-textarea__highlighter {
  height: 65px;
}

.beautiful-textarea-edit .beautiful-textarea__input {
  padding-right: 70px !important;
}

.beautiful-textarea__input {
  border: none;
  padding: 10px !important;
  overflow: auto !important;
}

.beautiful-textarea__input::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.beautiful-textarea__input::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.beautiful-textarea__input::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.beautiful-textarea__input:focus {
  border: none;
  outline: none;
}

.beautiful-textarea-min {
  height: 65px;
  margin-left: 1%;
  position: absolute;
  bottom: 20px;
  width: 77%;
  padding: 10px 0 0 10px;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  resize: none;
  transition: border-color 0.3s ease;
}

.beautiful-textarea-full-onlyChat {
  height: 65px;
  margin-left: 1%;
  position: absolute;
  bottom: 20px;
  width: 92%;
  padding: 10px 0 0 10px;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  resize: none;
  transition: border-color 0.3s ease;
}

.beautiful-textarea:hover {
  border-color: #4caf50;
}

.beautiful-textarea:focus {
  border-color: #2196f3;
  box-shadow: 0 0 10px rgba(33, 150, 243, 0.5);
}

/* Placeholder styles */
.beautiful-textarea::placeholder {
  color: #999;
  font-style: italic;
}

.please_login_again {
  font-size: 14px;
  color: #5b5b5b;
}

.session_expired {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .beautiful-textarea {
    width: 100%;
  }

  .container-2 {
    flex-direction: column;
    width: 100%;
  }

  /* .roomContainer_content_chat {
    height: 77%;
    position: relative;
  } */
  .textarea-container {
    bottom: 7px;
  }
  .count-text {
    height: 55px;
    width: 18%;
  }
}

@media (min-width: 769px) {
  .web-mobile-option {
    display: none !important;
  }
}

/* @media screen and (max-width: 540px) {
  .beautiful-textarea {
    width: 80%;
  }
}

@media screen and (max-width: 450px) {
  .beautiful-textarea {
    width: 73%;
  }
} */

@media screen and (max-height: 560px) {
  .tab-chat-content {
    height: 80%;
  }
}

@media screen and (max-height: 450px) {
  .tab-chat-content {
    height: 60%;
  }
}
@media screen and (max-width: 945px) and (min-height: 600px) {
  .tab-chat-content {
    height: 102%;
  }
}

@media screen and (max-height: 560px) {
  .tab-chat-content-image {
    height: 77%;
  }
}

@media screen and (max-height: 450px) {
  .tab-chat-content-image {
    height: 67%;
  }
}
@media screen and (max-width: 945px) and (min-height: 600px) {
  .tab-chat-content-image {
    height: 90%;
  }
}

.user-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, border-color 0.3s ease;
  border: 2px solid transparent;
}

.user-avatar-error {
  border-color: red;
}

.user-avatar:hover {
  transform: scale(1.1);
}

.chat-item-header {
  text-align: center;
  border: 1px solid #c9bbf1;
  border-radius: 5px;
  padding: 0px 4px;
  margin-top: -5px;
}

/* remove eyes in edge */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
