.example-data {
  display: flex;
  max-height: 60vh;
}

.table-header {
  max-height: 60vh;
  overflow-y: auto;
}

.example-data .table-container table {
  border-collapse: collapse;
  width: 100%;
}

.example-data .table-container th,
.example-data .table-container td {
  border-bottom: 1px solid #ddd !important;
  padding: 8px !important;
  height: 37px !important;
}

.example-data .table-container th {
  background-color: #674ea7;
  color: #ffffff;
}

.example-data .table-container th:first-child {
  border-top-left-radius: 8px;
}

.example-data .table-container th:last-child {
  border-top-right-radius: 8px;
}

.example-data .table-container td {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.example-data button {
  cursor: pointer;
  width: 50px;
}

.example-data .select-type {
  width: 120px !important;
  font-size: 14px;
}

.example-data .code-container {
  position: relative;
  display: inline-block;
}

.example-data .copy-button {
  position: absolute;
  top: 2px;
  right: 20px;
  z-index: 10;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  width: 70px;
  height: 20px;
}

.example-data .icon-download-csv {
  position: absolute;
  top: 2px;
  right: 100px; /* Adjust as needed */
  z-index: 10;
  cursor: pointer;
}
.example-data .copy-button:hover {
  background-color: #45a049;
}

.example-data .copied-button {
  position: absolute;
  top: 2px;
  right: 20px;
  z-index: 10;
  background-color: #e4ece4;
  color: rgb(8, 8, 8);
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  pointer-events: none;
  width: 70px;
  height: 20px;
}

.example-data .table-container .child-container {
  width: 100%;
}
.example-data .table-container .child-container table {
  display: flex;
  justify-content: start;
}

.example-data .table-container .child-container .select-type-child {
  font-size: 13px;
  width: 120px !important;
  margin: auto;
  height: 32px !important;
}

.example-data .table-container .child-container .input-type-child {
  width: 150px;
  margin: auto;
  height: 32px !important;
  font-size: 13px;
}

.button-ai-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  padding: 0;
  cursor: 'pointer';
  background: transparent;
  border: none;
  z-index: 99999999999;
}

.ai-helper {
  position: fixed;
  bottom: 100px;
  right: 60px;
  z-index: 99;
}

.image-ai-helper {
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.icon-ai-helper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.icon-history {
  width: 35px;
  height: 35px;
  cursor: pointer;
  /* color: #6d6363; */
  /* position: absolute; */
}

.review-code .code-container {
  position: relative;
  display: inline-block;
}

.review-code .copy-button {
  position: absolute;
  top: 0px;
  right: 15px;
  z-index: 10;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  width: 70px;
  height: 20px;
}

.review-code .copy-button:hover {
  background-color: #45a049;
}

.review-code .copied-button {
  position: absolute;
  top: 5px;
  top: 0px;
  right: 15px;
  background-color: #e4ece4;
  color: rgb(8, 8, 8);
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  pointer-events: none;
  width: 70px;
  height: 20px;
}

.tool-tip-chat-bot {
  z-index: 9999;
  max-width: 150px;
  text-align: center;
  background: #f3edff !important;
  color: black !important;
  border-radius: 20px !important;
  font-weight: bold;
}

.child-delete-item {
  transform: scale(0.8);
}

.example-data .table-container .child-container .child-row .child-td {
  border: none !important;
}
.example-data .table-container .child-container .child-row:last-child .child-td {
  border: none !important;
}

.select-option-data {
  width: 200px !important;
}

.example-data .description-data-example {
  width: 100%;
  height: 58vh;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.input-total-rows {
  width: 50px;
  text-align: center;
  height: 35px !important;
}

.text-image-modal {
  min-width: 200px;
  min-height: 400px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
  box-sizing: border-box;
}

.example-data .description-data-example:focus {
  border-color: #674ea7;
  outline: none;
}

.table td {
  max-width: 100px;
  padding: 0px 20px 0px 20px;
  white-space: nowrap; /* Ngăn chặn text quá dài bị xuống dòng */
  overflow: hidden; /* Ẩn phần dư thừa của text */
  text-overflow: ellipsis; /* Hiển thị dấu '...' khi text bị cắt */
}

.pagination-wrapper-aihelper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination li {
  margin-right: 10px;
}

.pagination li a {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.pagination li a:hover {
  background-color: #f0f0f0;
}

.pagination .active a {
  background-color: #674ea7;
  color: #fff;
  cursor: default;
}

.pagination .disabled a {
  pointer-events: none;
  color: #ccc;
}

.ai-history-td {
  padding-right: 20px !important;
  width: 30%;
}

.review-code .zoom-action {
  position: absolute;
  bottom: 20px;
  right: 10px;
  z-index: 10;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.example-data .zoom-action {
  position: absolute;
  bottom: 20px;
  right: 10px;
  z-index: 10;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.create-unit-test .description-unit-test {
  /* width: 27.5vw; */
  height: 30vh;
  border: 1px solid #ccc;
}
.description-unit-test:focus {
  border-color: #674ea7;
  outline: none;
}
.create-unit-test {
  display: flex;
  max-height: 60vh;
}
.create-unit-test .select-language-ut {
  width: 150px;
}

@media (max-width: 768px) {
  .ai-helper {
    display: none;
  }
}
