.input-file-container{
    cursor: pointer;
    .text-drag{
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
    }
    p{
        font-size: 11px;
        font-weight: 400;
        line-height: 16.5px;
    }
    span{
        font-weight: 600;
        font-size: 11px;
        line-height: 16.5px;
    }
}
.input-file-content{
    .input-file-content--item{
        background-color: #F4F4F4;
    }
}