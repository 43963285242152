.components {
    &__validator {
        &-input {
            input,
            textarea,
            select,
            > div {
                border-color: $color-red !important;
            }
        }
    }
}
