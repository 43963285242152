/* Comment style control bar */
/* ._styles-module__gridStage__2HbZ0 {
  display: grid;
}

._styles-module__grid4x4__3qF9s {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

._styles-module__container__14898 {
  width: 100%;
}
button._styles-module__hasDropdown__3Bgvz,
._styles-module__button__hRq5k {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  width: 78px;
  height: 54px;
  color: #333;
  font-size: 22px;
  margin-left: 10px;
}

._styles-module__dangerButton__Rt0RH {
  color: #fff;
}

button._styles-module__hasDropdown__3Bgvz p {
  display: none;
} */

.language {
  display: flex;
  align-items: center;
  gap: 10px;
}

.language label {
  color: #3c3c3c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.showStatus {
  display: none;
}

.react-tabs__tab {
  border-bottom: inset !important;
  padding: unset !important;
  bottom: unset !important;
  list-style: none !important;
}

.react-tabs__tab-list {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  border-bottom: unset !important;
  background-color: #f4f6fb !important;
  border-radius: 15px 0px 0px 0px !important;
  padding: 1% !important;
  margin-bottom: 0 !important;
}

.react-tabs__tab--selected {
  background-color: unset !important;
  border-color: #845bcf !important;
  width: 36px !important;
  height: 36px !important;
  border-radius: 50% !important;
  border-width: 2px !important;
  display: flex !important;
  justify-content: center !important ;
  align-items: center !important ;
}
.tab-chat > ul {
  margin: unset !important;
}
.open-icon,
.close-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.open-icon {
  display: none !important;
}

.close-icon {
  display: block !important;
}

.topBar-right-min {
  position: absolute;
  top: 0;
  left: 140px;
  display: flex;
  align-items: center;
  padding: 1%;
  gap: 10px;
  justify-content: space-between;
  width: 0;
}

.topBar-right-full {
  /* position: absolute; */
  /* top: 0;
  left: 210px; */
  background-color: #f4f6fb !important;
  border-radius: 15px 15px 0px 0px !important;
  border-radius: 15px 15px 0px 0px !important;
  display: flex;
  align-items: center;
  padding: 0.75%;
  gap: 10px;
  justify-content: space-between;
  flex: 1;
}

.topBar-right-full-onlyChat {
  /* position: absolute;
  top: 0;
  left: 140px; */
  display: flex;
  background-color: #f4f6fb !important;
  border-radius: 15px 15px 0px 0px !important;
  align-items: center;
  padding: 0.75%;
  gap: 10px;
  justify-content: space-between;
  flex: 1;
}

.topBar-fontsize_option {
  display: flex;
  align-items: center;
}
.topBar-export_quit {
  display: flex;
  gap: 15px;
}

.option-language-label {
  color: #3c3c3c;
  font-size: 14px;
  font-weight: 400;
}

/* .vertical-line {
  width: 1px;
  height: 50px;
  background-color: #E0E0E0;;
} */

.fontSize {
  padding: 0 10px;
  /* border-left: 1px #e0e0e0 solid; */
  border-right: 1px #e0e0e0 solid;
  display: flex;
  align-items: center;
  gap: 10px;
}

.hidden {
  display: none;
}

.video-call-turn-on-off {
  padding: 0 10px;
  border-right: 1px #e0e0e0 solid;
  display: flex;
  align-items: center;
  gap: 10px;
}
.vocab {
  padding: 0 10px;
  border-right: 1px #e0e0e0 solid;
  display: flex;
  align-items: center;
  gap: 10px;
}

.language-select {
  width: 65px !important;
}

.languageImageTarget {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}

.fontSize label {
  color: #3c3c3c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fontSize input {
  width: 50px;
  height: 30px;
  padding-left: 10px;
  border-radius: 10px;
  background: #fff;
  border-color: none;
  z-index: auto;
}

.export-btn {
  cursor: pointer;
  /* padding-right: 10px; */
}

.exportBtn {
  border-radius: 8px;
  background: #8962d0;
  width: fit-content;
  height: 45px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px;
  gap: 5px;
  text-align: center;
  border-color: unset;
  cursor: pointer;
}

.exportBtn label {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.text-chat-mine {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.text-chat-orther {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Dropdown */

.dropdown,
.dropdownactive {
  border-left: 1px #e0e0e0 solid !important;
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border-radius: 4px;
  padding: 0px 15px 0px 15px;
  height: 45px;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  background-color: unset;
}

.dd-button:hover {
  background-color: #eeeeee;
}

.dd-input {
  display: none;
}

.dd-menu {
  width: 240px;
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  list-style-type: none;
  gap: 10px;
  padding: 10px 0;
  z-index: 99;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.dd-menu li:nth-child(1),
.dd-menu li:nth-child(2) {
  margin-bottom: 10px !important;
}

.dd-menu li {
  color: #333;
  margin: auto;
  padding: 5px 10px;
  cursor: pointer;
  width: 80%;
  border-radius: 8px;
  border: 2px solid #d2d2d2;
  background: #fff;
  display: flex;
  margin: auto;
  font-size: 16px;
  gap: 5px;
  align-items: center;
  justify-content: left;
}

.dd-menu li:hover {
  background-color: #f6f6f6;
}

.dd-menu-selected {
  border: 2px solid #8962d0;
  color: #8962d0;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

/* START POP UP EXPORT */
.popup-export {
  display: flex !important;
  margin: auto !important;
  height: 70% !important;
  width: 70% !important;
  position: relative;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.popup-export-body {
  display: flex;
  gap: 20px;
}

.body-form-export {
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.chat-conversation {
  width: 200px;
  height: 140px;
  border-radius: 8px;
  border: 2px solid #8962d0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.chat-conversation label {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.export-icon {
  width: 50px;
  height: 50px;
}

.export-btn-body {
  width: 310px;
  height: 50px;
  border-radius: 8px;
  background: #8962d0;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* END POP UP EXPORT */

/* START POP UP EXPORT CHAT CONVERSATION */
.popup-chat-conversation {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.popup-chat-conversation-title {
  color: #5c576e;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.message-loading {
  font-size: 14px;
  font-weight: bold;
}

.popup-chat-conversation-body {
  width: 85%;
  height: 70%;
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  overflow: auto;
  font-size: 18px;
  padding: 15px;
}

/* END POP UP EXPORT CHAT CONVERSATION */

.send-button {
  height: 30px;
  margin-left: 92%;
  position: absolute;
  bottom: 40px;
  width: 50px !important;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  resize: none;
  background-color: #8962d0;
  transition: border-color 0.3s ease;
  bottom: 10px;
  right: 10px;
  font-size: 11px;
  color: #7f7575;
  cursor: pointer;
}

.send-button-min {
  height: 30px;
  margin-left: 88% !important;
  position: absolute;
  bottom: 15px;
  width: 10%;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  resize: none;
  background-color: #8962d0;
  transition: border-color 0.3s ease;
}

.send-button-full-onlyChat {
  height: 77px;
  margin-left: 94%;
  position: absolute;
  bottom: 20px;
  width: 5%;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  resize: none;
  background-color: #8962d0;
  transition: border-color 0.3s ease;
}

.quitBtn {
  border-radius: 8px;
  background: #9aa3b1;
  width: fit-content;
  height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px;
  gap: 5px;
  border-color: unset;
  padding: 20px;
  cursor: pointer;
}

.quitBtn label {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.btn-scrollToBottom {
  position: absolute;
  left: 50%;
  cursor: pointer;
  bottom: 0;
  transform: translateX(-50%);
}

.btn-scroll-hidden {
  display: none;
}

.btn-scroll-block {
  display: block;
}

.btn-scroll {
  width: 40px;
  height: 40px;
}

.show-scroll {
  display: none;
}

.orther_translate {
  color: #7f7575;
  font-weight: bold;
  font-size: 80%;
  text-decoration: underline;
}

.time_send {
  color: #7f7575;
  /* margin: 5px 0px 0px 10px; */
}

.text-style-vn {
  background: #e6f0ff;
  padding: 5px 24px;
  border-radius: 10px 10px 0 0;
  color: #000;
  border-bottom: 1px solid #d4d2ff;
  margin-bottom: 0;
  position: relative;
}

.text-style-vn-edit {
  border: 1px solid #351c75 !important;
}

.text-style-ja {
  color: #000;
  margin-bottom: 0;
}

.name-room-full-size {
  /* margin-right: 300px; */
}

@media (max-width: 768px) {
  .topBar-export_quit {
    display: none;
  }
  .name-room-full-size {
    display: none;
  }
  .kpt-button,
  .setting-room {
    display: none;
  }
  .kpt-switch {
    display: none;
  }
}

.text-style-ja-common {
  padding: 5px 24px;
  width: 'fit-content';
  position: relative;
}

.text-style-ja-translating {
  background: #f3edff;
  border-radius: 0 0 10px 10px;
  font-size: 12px !important;
}

.text-style-service-answer {
  background: #f3edff;
  border-radius: unset;
}

.text-style-service-answer + .text-style-service-answer {
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #d4d2ff;
}

.text-style-service-answer-gg {
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #d4d2ff;
}

.block-edit-deleted {
  display: none;
}

.options-left {
  right: 102%;
  top: 50%;
  transform: translateY(-50%);
}

.options-right {
  left: 102%;
  top: 50%;
  transform: translateY(-50%);
}

.opacity-delete {
  opacity: 0.5;
  background-color: #999999;
}

/* START MENTION NAME */
.beautiful-textarea__suggestions {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  bottom: 6.1rem !important;
  top: unset !important;
  margin-top: 0;
}
.beautiful-textarea__suggestions ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: start;
  padding: 10px !important;
}

.beautiful-textarea__suggestions ul li {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  background: #fff;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.beautiful-textarea__suggestions ul li:hover {
  color: #fff;
  background: #8e7cc3;
}
.mention-style {
  background: #d9d2e9;
  padding-top: 2px;
  padding-bottom: 2px;
}

/* START: Stop translation */
.stopTranslate {
  position: absolute;
  border-radius: 8px;
  border: 2px solid #9aa3b1;
  background: #fff;
  width: 50px;
  height: 36px;
  display: flex;
  color: #333;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px;
  cursor: pointer;
  transform: scale(0.9);
  bottom: 11px !important;
}

.stopTranslate .tooltip {
  position: absolute;
  display: none;
  background-color: #9d9d9d;
  color: #fff;
  padding: 5px;
  top: 125%;
  left: 50%;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #9d9d9d;
  width: 125px;
  transform: translateX(-50%);
  z-index: 99;
}

.zoomIn-screen {
  display: flex;
  justify-content: end;
  width: 103px;
}

.stopTranslate .tooltip::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-bottom-color: #9d9d9d;
  transform: translate(-50%, 0);
}

.stopTranslate:hover .tooltip {
  display: block;
}

.edited-text {
  font-size: smaller;
  color: #999;
  font-style: italic;
}

.updatevocabulary {
  .hightlight {
    td {
      background: #bbbaba !important;
    }
  }
}

.vocabulary-language {
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-right: 30px !important;
  line-height: 150% !important;
}

/* END: Stop translation */

/* END MENTION NAME  */

@media screen and (max-width: 1440px) {
  .roomContainer
    > div:nth-child(2)
    > div
    > div:nth-child(2)
    > div
    > div:nth-child(1)
    > button:nth-child(1),
  .roomContainer
    > div:nth-child(2)
    > div
    > div:nth-child(2)
    > div
    > div:nth-child(2)
    > button:nth-child(1),
  .roomContainer > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(3),
  .roomContainer.video > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(4) {
    width: 65px !important;
    height: 55px !important;
  }
  /* Comment style control bar */
  /* button._styles-module__hasDropdown__3Bgvz,
  ._styles-module__button__hRq5k {
    width: 65px;
    height: 55px;
  } */
}

@media screen and (max-width: 1230px) {
  .topBar-right-full {
    /* padding: unset; */
  }
}

@media screen and (max-width: 1000px) {
  .exportBtn label {
    display: none;
  }

  .fontSize label {
    display: none;
  }

  .send-button {
    width: 15%;
    margin-left: 84%;
  }
}

@media screen and (max-width: 840px) {
  .exportBtn {
    width: 50px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .roomContainer.chat {
    margin-top: 0px;
  }

  .zoomIn-screen {
    display: none;
  }
  /* Start video grid */
  /* .roomContainer.video > div:nth-child(2) > div {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  /* Video dau tien */
  .roomContainer.video > div:nth-child(2) > div > div:nth-child(1) {
    display: block !important;
    height: 30% !important;
  }

  /* Phần video thứ 2 */
  .roomContainer.video > div:nth-child(2) > div > div:nth-child(2),
  .roomContainer.video-full > div:nth-child(2) > div > div:nth-child(2) {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-template-rows: auto !important;
  }

  .roomContainer.video > div:nth-child(2) > div > div:nth-child(2) > div {
    width: unset !important;
  }
  /* End video grid */

  /* Participant bar (name) */
  .roomContainer.video > div:nth-child(2) > div > div:nth-child(2) > div > div:nth-child(2) {
    left: 4px !important;
    bottom: 4px !important;
    min-width: 160px !important;
    height: 20px !important;
    border-radius: 6px !important;
    opacity: 0.4 !important;
    background: #000 !important;
    color: #fff !important;
    padding: 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    display: flex !important;
    gap: 8px !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }

  /* Contact bar */
  .roomContainer.video > div:nth-child(2) > div {
    row-gap: 0px !important;
    grid-row-gap: 0px !important;
  }
  .roomContainer.video > div:nth-child(2) > div > div:nth-child(3) {
    height: 80px !important;
  }
  /* Mic button */
  .roomContainer.video
    > div:nth-child(2)
    > div
    > div
    > div
    > div:nth-child(1)
    > button:nth-child(1),
  .roomContainer.video > div:nth-child(2) > div > div:nth-child(3) > div > button:nth-child(1) {
    background: #fff !important;
    border-radius: 10px !important;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25) !important;
    color: #333 !important;
    height: 55px !important;
    /* margin-left: 10px !important; */
    width: 65px !important;
  }

  .roomContainer.video
    > div:nth-child(2)
    > div
    > div
    > div
    > div:nth-child(1)
    > button:nth-child(2) {
    display: none !important;
  }

  /* Video button */
  .roomContainer.video
    > div:nth-child(2)
    > div
    > div
    > div
    > div:nth-child(2)
    > button:nth-child(1),
  .roomContainer.video > div:nth-child(2) > div > div:nth-child(3) > div > button:nth-child(2) {
    background: #fff !important;
    border-radius: 10px !important;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25) !important;
    color: #333 !important;
    height: 55px !important;
    /* margin-left: 10px !important; */
    width: 65px !important;
  }

  .roomContainer.video
    > div:nth-child(2)
    > div
    > div
    > div
    > div:nth-child(2)
    > button:nth-child(2) {
    display: none !important;
  }

  /* End button */
  .roomContainer.video
    > div:nth-child(2)
    > div
    > div
    > div:nth-child(1)
    > button:nth-child(3).btn-end-call,
  .roomContainer.video > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(3),
  .roomContainer.video
    > div:nth-child(2)
    > div
    > div:nth-child(3)
    > div
    > button._styles-module__button__hRq5k._styles-module__dangerButton__Rt0RH {
    background: #981010 !important;
    border-radius: 10px !important;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25) !important;
    font-size: 22px !important;
    height: 55px !important;
    /* margin-left: 10px !important; */
    width: 65px !important;
    color: #fff !important;
  }
  .roomContainer.video
    > div:nth-child(2)
    > div
    > div
    > div:nth-child(1)
    > button:nth-child(3).btn-end-call {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .send-button {
    margin-left: 88%;
    width: 11% !important;
  }

  .chat {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
  }

  .topBar-right-full {
    padding: 0.5%;
    border-radius: 0 !important;
  }

  .count-text {
    height: 25px;
    position: absolute;
    right: 50px;
    width: 18% !important;
  }
  .vocab {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    border-left: none;
    border-right: none;
    font-size: 12px;
  }
  .select-component-mobile {
    font-size: 12px;
    padding: 4px 0 4px 10px !important;
    background-position: right 0.25rem center !important;
  }

  .web-lang-option .vocab .language-select {
    width: 50px !important;
  }

  .topBar-fontsize_option {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .fontSize img {
    display: block;
  }

  .send-button {
    width: 15%;
    margin-left: 84%;
  }
}

@media screen and (max-width: 450px) {
  .roomContainer.video > div:nth-child(2) > div > div:nth-child(2) {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-template-rows: auto;
  }
  .exportBtn {
    width: 50px;
  }

  .topBar-right-full {
    padding: unset;
  }

  .send-button {
    width: 20%;
    margin-left: 79%;
  }
}

@media screen and (max-width: 390px) {
  .fontSize {
    padding: 0 3px;
  }
}

/* Video grid */
/* .roomContainer.video > div:nth-child(2) > div > div:nth-child(1),
.roomContainer.video-full > div:nth-child(2) > div > div:nth-child(1)  {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
} */
.roomContainer.video > div:nth-child(2) > div > div:nth-child(1) > div > div:nth-child(2),
.roomContainer.video-full > div:nth-child(2) > div > div:nth-child(1) > div > div:nth-child(2) {
  position: absolute !important;
  left: 3px !important;
  bottom: 3px !important;
  min-width: 160px !important;
  max-width: 265px !important;
  height: 20px !important;
  border-radius: 6px !important;
  opacity: 0.4 !important;
  background: #000 !important;
  color: #fff !important;
  padding: 4px !important;
  font-weight: 500 !important;
  font-size: 11.5px !important;
  display: flex !important;
  gap: 8px !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
/* Mic button */
.roomContainer.video
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > button:nth-child(1),
.roomContainer.video > div:nth-child(2) > div > div:nth-child(2) > div > button:nth-child(1),
.roomContainer.video-full
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > button:nth-child(1),
.roomContainer.video-full > div:nth-child(2) > div > div:nth-child(2) > div > button:nth-child(1) {
  background: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25) !important;
  color: #333 !important;
  height: 54px !important;
  /* margin-left: 10px !important; */
  width: 78px !important;
}

.roomContainer.video
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > button:nth-child(2),
.roomContainer.video-full
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > button:nth-child(2) {
  display: none !important;
}
.roomContainer.video
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > button:nth-child(1)
  > svg {
  height: 22px !important;
}

/* Video button */
.roomContainer.video
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > button:nth-child(1),
.roomContainer.video > div:nth-child(2) > div > div:nth-child(2) > div > button:nth-child(2),
.roomContainer.video-full
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > button:nth-child(1),
.roomContainer.video-full > div:nth-child(2) > div > div:nth-child(2) > div > button:nth-child(2) {
  background: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25) !important;
  color: #333 !important;
  height: 54px !important;
  /* margin-left: 6px !important; */
  width: 78px !important;
}

.roomContainer.video > div:nth-child(2) > div > div:nth-child(2) > div > button:nth-child(2) > svg {
  height: 22px !important;
}

.roomContainer.video
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > button:nth-child(2),
.roomContainer.video-full
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > button:nth-child(2) {
  display: none !important;
}
/* Share screen button */
.roomContainer.video > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(3),
.roomContainer.video-full > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(3) {
  background: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25) !important;
  color: #333 !important;
  height: 54px !important;
  /* margin-left: 10px !important; */
  width: 78px !important;
}
.roomContainer.video > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(3) > svg {
  height: 22px !important;
}

/* End button */
.roomContainer.video > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(4),
.roomContainer.video-full > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(4) {
  background: #981010 !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25) !important;
  color: #fff !important;
  font-size: 22px !important;
  height: 54px !important;
  /* margin-left: 10px !important; */
  width: 78px !important;
}

/* Hidden text in button */
/* Button mic */
.roomContainer.video > div:nth-child(2) > div > div:nth-child(2) > div > button:nth-child(1),
.roomContainer.video-full > div:nth-child(2) > div > div:nth-child(2) > div > button:nth-child(1) {
  font-size: 0px !important;
}
.roomContainer.video
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > button:nth-child(1)
  > :first-child,
.roomContainer.video-full
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > button:nth-child(1)
  > :first-child {
  font-size: 22px !important;
  margin-right: 0;
}

/* Button video */
.roomContainer.video
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > button:nth-child(1),
.roomContainer.video-full
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > button:nth-child(1) {
  font-size: 0px !important;
}
.roomContainer.video
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > button:nth-child(1)
  > :first-child,
.roomContainer.video-full
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > button:nth-child(1)
  > :first-child {
  font-size: 22px !important;
  margin-right: 0;
}

/* Button share screen */
.roomContainer.video > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(3),
.roomContainer.video-full > div:nth-child(2) > div > div > div:nth-child(1) > button:nth-child(3) {
  font-size: 0px !important;
}

.roomContainer.video
  > div:nth-child(2)
  > div
  > div
  > div:nth-child(1)
  > button:nth-child(3)
  > :first-child,
.roomContainer.video-full
  > div:nth-child(2)
  > div
  > div
  > div:nth-child(1)
  > button:nth-child(3)
  > :first-child {
  font-size: 22px !important;
  margin-right: 0;
}

.roomContainer.video > div:nth-child(2) > div > div:nth-child(2) > div {
  display: flex;
  align-items: center !important;
}

.name-room {
  color: #000;
  font-size: 20px;
  white-space: nowrap;
  font-weight: bold;
}

.suggest-button {
  height: 36px;
  position: absolute;
  bottom: 90px;
  width: 25px;
  cursor: pointer;
  margin-left: 10px !important;
  bottom: 11px !important;
}

.suggest-button-min {
  height: 25px;
  margin-left: 15px !important;
  position: absolute;
  width: 25px;
  cursor: pointer;
  bottom: 18px !important;
}

.send-button-full-onlyChat {
  height: 77px;
  margin-left: 94%;
  position: absolute;
  bottom: 20px;
  width: 5%;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  resize: none;
  background-color: #8962d0;
  transition: border-color 0.3s ease;
}

.count-text {
  height: 25px;
  position: absolute;
  width: 100px;
  bottom: 2px !important;
  right: 40px !important;
  font-size: 11px !important;
  color: #7f7575 !important;
}

.count-text-min {
  height: 35px;
  margin-left: 70% !important;
  position: absolute;
  width: 18%;
  bottom: -3px !important;
  font-size: 11px !important;
  color: #7f7575 !important;
}

.delete-button-min {
  margin-left: 76% !important;
}

.switch-icon {
  cursor: pointer;
}

._2VE66 {
  display: flex;
  flex-direction: column;
}

._styles-module__stage__2VE66 {
  display: flex;
  flex-direction: column;
}

._styles-module__controlsArea__1smc2 {
  z-index: 999;
  background-color: transparent;
  margin-bottom: 15px;
}

._1smc2 {
  z-index: 999;
  background-color: transparent;
  margin-bottom: 15px;
}

.translate-file {
  height: 36px;
  position: absolute;
  width: 25px;
  cursor: pointer;
  margin-left: 102px !important;
  bottom: 11px !important;
}

.excel-icon {
  width: 70px;
  height: 70px;
}

.languageTranslateFile {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}

.file-translate-upload {
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 400px;
}
.uploaded-file {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.file-info {
  word-wrap: break-word;
  max-width: 270px;
}
.delete-icon {
  position: absolute;
  top: 0px;
  right: 8px;
  cursor: pointer;
  font-size: 20px;
  color: gray;
}

.choose-another-file {
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.file-size {
  font-size: 12px;
}

.roomContainer.video button svg {
  font-size: 22px !important;
}

@media screen and (max-width: 800px) {
  .roomContainer.video > div:nth-child(2) > .video-mobile-container {
    position: relative;
  }
  /* .roomContainer.video > div:nth-child(2) > .video-mobile-container .video-control-mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1vh !important;
  } */
  .roomContainer.video
    > div:nth-child(2)
    > .video-mobile-container
    > .video-mobile-other-paticipants {
    width: 100%;
  }

  .roomContainer.video
    > div:nth-child(2)
    > .video-mobile-container
    > .video-mobile-other-paticipants
    > .col-6 {
    padding: 0 !important;
  }

  .video-mobile-other-paticipants {
    .video-ratio {
      width: 100%;
      height: 100%;
    }
  }
  .fontSize {
    display: none;
  }

  .video-mobile-stage video {
    object-fit: cover !important;
  }
}
