.notfound-permission {
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40vw;
  height: 50vh;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @media (max-width: 1600px) {
    height: 80vh !important;
    img {
      height: 70px;
    }
    h2 {
      padding-top: 20px;
    }
    p {
      padding: 10px 0;
    }
  }
  h2 {
    padding-top: 40px;
    font-size: 50px;
  }
  p {
    padding-top: 20px;
    font-size: 25px;
    padding-bottom: 20px;
  }
  .button-custom-back {
    background: #083374;
    width: 200px;
    height: 50px;
    font-size: 18px;
    color: white;
    font-family: Arial, sans-serif;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .button-custom-back:hover {
    background: #0c4aad;
  }
}

.page-not-allow-system {
  display: none;
}

@media screen and (max-width: 540px) {
  .page-not-allow-system {
    display: block;
  }
  .notfound-permission {
    padding: 0px !important;
    height: 40vh !important;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: auto;
    height: 50vh;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    img {
      height: 70px;
    }
    h2 {
      padding-top: 0px !important;
      font-size: 35px;
    }
    p {
      padding: 0px !important;
      font-size: 20px;
    }
  }
}
