.components {
  &__tabs {
    margin-top: -24px;
    .nav-item .nav-link {
      width: unset !important;
      flex: unset !important;
      gap: unset !important;
      height: unset !important;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #959595;
      display: flex;
      align-items: center;
    }

    &--underline-center {
      background: #fff;
      padding: 25px 0;
      margin: 0 -16px;
      justify-content: center;
      border-bottom: 0;
      .nav-item {
        display: flex;
        cursor: pointer;
        .nav-link {
          padding: 5px 1vw;
          border: none !important;
        }

        .nav-link:hover {
          background: #fff !important;
          color: #009692 !important;
          // color: #fff;
        }

        // c {
        //   border-right: 1px solid #c7c7c7;
        // }
        &:not(:last-child)::after {
          content: '';
          width: 32px;
          height: 0px;
          border: 1px solid #c7c7c7;
          transform: rotate(90deg);
          align-self: center;
        }
      }
      .nav-item .active {
        color: #009692 !important;
        border: none !important;
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 80px;
          height: 2px;
          background-color: #009692;
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
