.components {
  &__modal {
      .modal-content {
          padding: 16px 0px 16px 0px;
          .modal_permision {
              .title_content {
                  color: #000;
                  font-family: Tahoma;
                  font-size: 11px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
              }
              .form-check-label{
                  color: #000;
                  font-family: Tahoma;
                  font-size: 11px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
              }
          }
      }
      &-header {
          position: relative;
          padding-bottom: 16px;
      }
      &-title {
          font-weight: 700;
          font-size: 17px;
          line-height: 16.9px;
          text-align: center;
          text-transform: uppercase;
          color: #000;
      }
      &-icon {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          &-only {
              position: absolute;
              right: 16px;
              top: 20px;
              transform: translateY(-50%);
              cursor: pointer;
          }
      }
      &-content {
          // padding: 30px 0;
      }
      &-footer {
          gap: 10px;
          button {
              border-radius: 3px;
              width: 170px;
              height: 35px;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              text-align: center;
              letter-spacing: 0.02em;
          }
          .button-close {
              background: #ffffff;
              border: 1px solid #11b9b5;
              color: #11b9b5;
          }
          .button-submit {
              background: #11b9b5;
              border: 1px solid #11b9b5;
              color: #ffffff;
          }
      }
  }
}
