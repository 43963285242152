.components {
  &__language {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.44);
    &--japan {
      width: 50px;
      cursor: pointer;
      @include responsive(max-sm) {
        img {
          width: 25px;
        }
      }
    }
    &--global {
      width: 50px;
      cursor: pointer;
      @include responsive(max-sm) {
        img {
          width: 25px;
        }
      }
    }
    &--line {
      height: 50px;
      width: 1px;
      background: #107a78;
      margin: 0 20px;
      @include responsive(max-sm) {
        height: 30px;
      }
    }
    .active {
      font-weight: 600 !important;
      color: #ffffff !important;
    }
  }
}
