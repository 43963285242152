.components {
  &__loader {
    z-index: 9999999;
    background: $color-black-opacity;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: hidden !important;
    &-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
